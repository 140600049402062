import { useEffect, useMemo, useState } from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { DialogTitle, KrowTransition } from 'shared/components'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import { addSnack } from 'store/snacks'
import { useUpdateApplicantMutation } from 'store/applicants/applicants.api'
import { useAllOpeningsQuery } from 'store/openings/opening.api'
import { useStyles } from './ConfirmationUpdateDialog.styles'
import { useAppDispatch } from 'shared/hooks/redux'
import { useOrgSettings } from 'shared/hooks/useOrgSettings'
import { Checkbox, Stack } from '@mui/material'
import capitalize from 'lodash/capitalize'

function ConfirmationUpdateDialog({
  open,
  onClose,
  onUpdate,
  title,
  description,
  applicant,
  localityId,
  selectedStatus,
  children,
}) {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const { data = { data: [] } } = useAllOpeningsQuery({ localityId: localityId })

  const options = useMemo(
    () =>
      data?.data?.map((opening) => ({
        name: opening.position.name,
        value: opening.id,
      })) || [],
    [data?.data]
  )

  const { control, formState, handleSubmit, setValue, watch, getValues } = useForm()

  const statusActionAllowedToModifyOpenings =
    selectedStatus?.action === 'hire' || selectedStatus?.action === 'keep_warm'

  const confirmationRequired =
    selectedStatus?.action === 'hire' || selectedStatus?.action === 'reject'

  const { aliases } = useOrgSettings()
  const aliasOptions = useMemo(
    () =>
      aliases?.[selectedStatus.name].map((item) => ({
        name: item.name,
        value: item.name,
      })) ?? [],
    [aliases, selectedStatus.name]
  )

  const [update] = useUpdateApplicantMutation()

  const onSubmit = handleSubmit(async ({ opening, alias }) => {
    try {
      if (opening) {
        await update({ id: applicant.id, opening_id: opening.value }).unwrap()
      }
      onUpdate({ ...selectedStatus, alias: alias?.value })
    } catch (e) {
      dispatch(addSnack({ message: 'Unable to update candidate status.', severity: 'error' }))
    }
  })

  const stateTextToRender = selectedStatus.action ? selectedStatus.action.replace(/_/g, ' ') : ''

  useEffect(() => {
    if (open) {
      setValue(
        'opening',
        options?.find((opening) => opening.value === applicant?.opening?.id) ?? null
      )
      setValue(
        'alias',
        aliasOptions?.find((alias) => alias.value === applicant?.state_alias) ?? null
      )
    }
  }, [open, options, aliasOptions, applicant])

  const [confirmation, setConfirmation] = useState(false)

  const updateIsDisabled =
    (confirmationRequired && !confirmation) || (!!aliasOptions.length && !watch('alias'))

  return (
    <Dialog
      data-cy="confirmation-dialog"
      open={open}
      TransitionComponent={KrowTransition}
      keepMounted
      onClose={onClose}
      aria-labelledby="confirmation-title"
      aria-describedby="confirmation_dialog"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="h3" id="organization-title">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Typography className={classes.dialogDescription}>{description}</Typography>
        {children}
        <Grid className={classes.grid} container>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Full Name
            </Typography>
            <Typography lineHeight="27px">{applicant?.full_name}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.grid} container>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Selected Status
            </Typography>
            <Typography lineHeight="27px" className={classes.selectedStatus}>
              {stateTextToRender}
            </Typography>
          </Grid>
        </Grid>
        {statusActionAllowedToModifyOpenings && (
          <Box className={classes.statusDropdown}>
            <Controller
              name="opening"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => field.onChange(value)}
                  id="opening_id"
                  data-cy="opening"
                  options={options}
                  disableClearable
                  isOptionEqualToValue={(option, selected) => option.value === selected.value}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Opening"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Box>
        )}
        {!!aliasOptions.length && (
          <Box>
            <Box className={classes.statusDropdown}>
              <Controller
                name="alias"
                control={control}
                render={({ field, fieldState }) => {
                  const { ...rest } = field
                  return (
                    <Autocomplete
                      {...rest}
                      onChange={(e, option) => rest.onChange(option)}
                      id="alias"
                      data-cy="alias"
                      options={aliasOptions}
                      disableClearable
                      isOptionEqualToValue={(option, selected) => option.value === selected.value}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Alias"
                          variant="outlined"
                          error={!!fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )
                }}
              />
            </Box>
          </Box>
        )}
        {confirmationRequired && (
          <Stack direction="row" spacing={0.1} alignItems="center">
            <Checkbox
              value={confirmation}
              onChange={(value) => setConfirmation(value.target.checked)}
            />
            <Typography variant="body2">
              I confirm that "{capitalize(stateTextToRender)}" status will be final and cannot be
              changed.
            </Typography>
          </Stack>
        )}
      </DialogContent>

      <DialogActions sx={{ py: 2 }}>
        <Button autoFocus color="primary" variant="outlined" onClick={onClose} size="small">
          Cancel
        </Button>
        <LoadingButton
          loading={formState.isSubmitting}
          onClick={onSubmit}
          disabled={updateIsDisabled}
          variant="contained"
          color="primary"
          size="small"
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationUpdateDialog
