import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from './store.utils'

const tagTypes = [
  'AccountManager',
  'AccountManagerAssignment',
  'Alert',
  'Applicant',
  'Area',
  'Authentication',
  'Availability',
  'Calendar',
  'Country',
  'Export',
  'Inclusion',
  'Interview',
  'Location',
  'Me',
  'Member',
  'Note',
  'Opening',
  'Organization',
  'Position',
  'Preoccupation',
  'Profile',
  'Questionnaire',
  'HiringEvents',
  'Image',
  'Region',
  'Response',
  'Settings',
  'User',
  'Directions',
  'OrganizationFont',
  'Authentication',
  'Schedule',
  'Market',
  'Classification',
  'Automation',
] as const

export const api = createApi<ReturnType<typeof axiosBaseQuery>, {}, 'api', typeof tagTypes[number]>(
  {
    reducerPath: 'api',
    tagTypes,
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({}),
  }
)
